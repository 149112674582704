import React from 'react';
import styled from 'styled-components';
import { InnerWrap } from 'components/composition/InnerWrap/InnerWrap';
import { Grid } from 'components/composition/Grid/Grid';
import { Title } from 'components/content/Title/Title';
import { Icon } from 'components/content/Icon/Icon';
import { Lead } from 'components/content/Lead/Lead';

const Container = styled.section`
  background-image: url(/images/world-map-blue.png);
  background-repeat: no-repeat;
  background-size: 85%;
  background-position: right 40%;

  @media screen and (min-width: ${props => props.theme.breakpoint.tablet}) {
    background-size: 50%;
    background-position: top right;
  }
`;

const Intro = styled(Lead)`
  margin: ${props => props.theme.spacing.xs} 0 ${props => props.theme.spacing.lg};
  max-width: 23em;
`;

const GridItem = styled(Grid.Item)`
  font-size: ${props => props.theme.font.size.lg};
`;

const IconContainer = styled.div`
  font-size: ${props => props.theme.font.size.lg};
  margin-bottom: ${props => props.theme.spacing.xs};
`;

export const TargetMeasureAct = () => (
  <Container>
    <InnerWrap>
      <Title as="h2" color="brandSecondary">
        Target. Measure. Act.
      </Title>
      <Intro large>
        The Food Waste Atlas allows governments and companies to understand how food loss{' '}
        and waste is occurring, helping them play their part in creating a sustainable food{' '}
        system.
      </Intro>
      <Grid spacing="lg" wrap>
        <GridItem xs={12} tablet={4}>
          <IconContainer>
            <Icon type="track" size="xl" color="brandPrimary" />
          </IconContainer>
          <p>
            Access global data to help develop baselines and appropriate targets
          </p>
        </GridItem>
        <GridItem xs={12} tablet={4}>
          <IconContainer>
            <Icon type="timeline" size="xl" color="brandPrimary" />
          </IconContainer>
          <p>
            Align your measurement with international standards, and share data transparently
          </p>
        </GridItem>
        <GridItem xs={12} tablet={4}>
          <IconContainer>
            <Icon type="globe" size="xl" color="brandPrimary" />
          </IconContainer>
          <p>
            Gain insights on food loss and waste by product, by region, and by country, to{' '}
            take action on reducing food waste
          </p>
        </GridItem>
      </Grid>
    </InnerWrap>
  </Container>
);
