export const times = (value: string, multiplier: number) => {
  const amount = Number(value.replace(/em|rem|px/g, ''));
  const unit = value.replace(/([0-9]|\.\w)/g, '');
  return `${amount * multiplier}${unit}`;
};

export const rem = (value: string) => {
  const amount = Number(value.replace(/em|rem|px/g, ''));
  return `${amount}rem`;
};
