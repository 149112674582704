import React from 'react';
import styled from 'styled-components';
import { InnerWrap } from 'components/composition/InnerWrap/InnerWrap';
import { Grid } from 'components/composition/Grid/Grid';
import { Title } from 'components/content/Title/Title';
import { Icon } from 'components/content/Icon/Icon';

const List = styled.ol`
  padding: 0;
  margin: ${props => props.theme.spacing.sm} 0 0 ${props => props.theme.spacing.md};
  font-size: ${props => props.theme.font.size.xl};
  font-weight: 300;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 9em;
`;

export const ReducingFoodWaste = () => (
  <section>
    <InnerWrap>
      <Grid wrap>
        <Grid.Item xs={12} tablet={8}>
          <Title as="h2" color="brandSecondary">
            Reducing food waste is a triple win.
          </Title>
          <List>
            <li>Saves money for farmers, companies, and households.</li>
            <li>Makes more food available to those in need.</li>
            <li>Reduces the pressure on climate, water, and land resources</li>
          </List>
        </Grid.Item>
        <Grid.Item xs={12} tablet={4}>
          <IconContainer>
            <Icon type="podium" color="accent" />
          </IconContainer>
        </Grid.Item>
      </Grid>
    </InnerWrap>
  </section>
);
