import styled from 'styled-components';

const StyledLayout = styled.div`
  position: relative;
  z-index: 1;

  @media(min-width: ${props => props.theme.breakpoint.desktop}) {
    display: flex;
    padding: 0 ${props => props.theme.spacing.md};
  }

  @media(min-width: ${props => props.theme.containerMaxWidth}) {
    padding: 0;
  }
`;

const Main = styled.div<{ bumpMeUp?: boolean }>`
  @media(min-width: ${props => props.theme.breakpoint.desktop}) {
    width: auto;
    flex-grow: 1;
    flex-basis: 0;
    margin-top: ${props => props.bumpMeUp ? `-${props.theme.spacing.lg}` : 0};
  }
`;

const Aside = styled.aside<{ bumpMeUp?: boolean }>`
  margin-top: ${props => props.theme.spacing.lg};

  @media(min-width: ${props => props.theme.breakpoint.desktop}) {
    width: 25%;
    margin-left: ${props => props.theme.spacing.lg};
    margin-top: ${props => props.bumpMeUp ? `-${props.theme.spacing.lg}` : 0};
  }
`;

const Centered = styled.div`
  margin: ${props => `${props.theme.spacing.lg} 0`};

  @media(min-width: ${props => props.theme.breakpoint.desktop}) {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 35em;
    width: 100%;
  }
`;

/** @component */
export const Layout = Object.assign(StyledLayout, { Main, Aside, Centered });
