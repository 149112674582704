import styled from 'styled-components';

interface SpacingProps {
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
}

export const Spacing = styled.div<SpacingProps>`
  ${props => props.top ? `
    margin-top: ${props.theme.spacing[props.top]};
  ` : ''}
  ${props => props.bottom ? `
    margin-bottom: ${props.theme.spacing[props.bottom]};
  ` : ''}
  ${props => props.left ? `
    margin-left: ${props.theme.spacing[props.left]};
  ` : ''}
  ${props => props.right ? `
    margin-right: ${props.theme.spacing[props.right]};
  ` : ''}
`;
