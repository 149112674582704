import React from 'react';
import styled from 'styled-components';
import { Wrap } from 'components/composition/Wrap/Wrap';

const StyledHero = styled.header`
  background-color: ${props => props.theme.colors.brandPrimary};
  color: ${props => props.theme.colors.white};
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (min-width: ${props => props.theme.breakpoint.tablet}) {
    min-height: 15em;
  }

  ${Wrap} {
    align-items: center;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${props => props.theme.breakpoint.tablet}) {
      flex-direction: row-reverse;
    }
  }
`;

const HeroImage = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpoint.tablet}) {
    margin-left: -10%;
  }

  img {
    display: block;
    height: auto;
    margin: auto;
    max-width: 25em;
    width: 100%;
  }

  @media screen and (min-width: ${props => props.theme.breakpoint.desktop}) {
    max-width: 50em;

    img {
      max-width: 30em;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoint.max}) {
    img {
      max-width: 100%;
    }
  }
`;

const HeroContent = styled.div`
  flex: 1 1 auto;
  padding: ${props => `${props.theme.spacing.lg} ${props.theme.spacing.root}`};
  padding-top: 0;
  z-index: 1;

  @media screen and (min-width: ${props => props.theme.breakpoint.tablet}) {
    min-width: 37em;
    padding: ${props => `${props.theme.spacing.lg} calc(${props.theme.spacing.root} + ${props.theme.spacing.md})`};
  }

  @media screen and (min-width: ${props => props.theme.breakpoint.max}) {
    font-size: ${props => props.theme.font.size.lg};
    padding: ${props => `${props.theme.spacing.lg} ${props.theme.spacing.max}`};
  }
`;

interface HomeHeroProps {
  children: React.ReactNode;
}

export const HomeHero = ({ children }: HomeHeroProps) => (
  <StyledHero>
    <Wrap>
      <HeroImage>
        <img
          src="/images/apple-globe.jpg"
          alt="An old apple that looks like the Earth with countries as bits out of the flesh."
        />
      </HeroImage>
      <HeroContent>
        {children}
      </HeroContent>
    </Wrap>
  </StyledHero>
);
