import styled from 'styled-components';
import { rem } from 'lib/styled-helpers';

interface TitleProps {
  color?: string;
  inset?: boolean;
  spacing?: boolean;
}

/** @component */
export const Title = styled.h1<TitleProps>`
  color: ${props => props.theme.colors[props.color || 'white']};
  margin: 0 0 ${props => props.spacing ? props.theme.spacing.sm : 0} 0;

  @media(min-width: ${props => props.theme.breakpoint.tablet}) {
    padding-left: ${props => props.inset ? rem(props.theme.spacing.lg) : 0};
  }
`;
