import React, { useReducer } from 'react';
import Head from 'next/head';
import Router from 'next/router';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import styled from 'styled-components';

import { HomeHero } from 'components/canvas/HomeHero/HomeHero';
import { SearchForm } from 'components/canvas/SearchForm/SearchForm';
import { Layout } from 'components/composition/Layout/Layout';
import { FormGroup } from 'components/composition/FormGroup/FormGroup';
import { Spacing } from 'components/composition/Spacing/Spacing';
import { Wrap } from 'components/composition/Wrap/Wrap';
import { Title } from 'components/content/Title/Title';
import { Lead } from 'components/content/Lead/Lead';
import { TargetMeasureAct } from 'components/content/TargetMeasureAct/TargetMeasureAct';
import { SustainableDevelopment } from 'components/content/SustainableDevelopment/SustainableDevelopment';
import { ReducingFoodWaste } from 'components/content/ReducingFoodWaste/ReducingFoodWaste';
import { Organizations } from 'components/content/Organizations/Organizations';
import { Button } from 'components/controls/Button/Button';
import { Autocomplete } from 'components/controls/Autocomplete/Autocomplete';

import { noAuto } from 'lib/autocomplete';

const AdvancedSearch = dynamic<any>(() => (
  import('components/controls/AdvancedSearch/AdvancedSearch').then((mod) => mod.AdvancedSearch)
), { ssr: false });

const initialState = {
  region: '',
  food: '',
  submitted: false,
  advancedOpen: false,
};

const HomeLead = styled(Lead)`
  max-width: 20em;
`;

const MediaBr = styled.br`
  display: none;

  @media screen and (min-width: ${props => props.theme.breakpoint.max}) {
    display: block;
  }
`;

function reducer(state, { field, value }) {
  return {
    ...state,
    [field]: value,
  };
}

const Home = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { region, food, advancedOpen } = state;

  const handleChange = ({ target: { name, value } }) => {
    dispatch({
      field: name,
      value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Grab region and food and send to results
    const query: { region?: string; food?: string } = {};

    if (region !== '') query.region = region;
    if (food !== '') query.food = food;

    await Router.push({
      pathname: '/records',
      query,
    });
    window.scrollTo(0, 0);
  };

  const toggleAdvanced = (e) => {
    e.preventDefault();
    dispatch({
      field: 'advancedOpen',
      value: !advancedOpen,
    });
  };

  return (
    <>
      <Head>
        <title>The Food Waste Atlas</title>
      </Head>
      <HomeHero>
        <Spacing bottom="md">
          <Title as="h1">Tracking global food waste</Title>
        </Spacing>
        <HomeLead large>
          Helping organizations to play their part in creating a sustainable food system for all.
        </HomeLead>
      </HomeHero>
      <Wrap>
        <Layout>
          <Layout.Main bumpMeUp>
            <Spacing bottom="fluid">
              <SearchForm method="GET" action="/records" onSubmit={handleSubmit} autoComplete="off">
                <SearchForm.Title>
                  <Title as="h3" color="brandSecondary">
                    Search for <MediaBr />
                    food waste records
                  </Title>
                  <SearchForm.TitleLink>
                    <Link href="/records/[id]" as="/records/f2-s1-46"><a>See an example</a></Link>
                  </SearchForm.TitleLink>
                </SearchForm.Title>
                <SearchForm.Field first>
                  <FormGroup inline>
                    <FormGroup.Label htmlFor="region">
                      Region
                    </FormGroup.Label>
                    <FormGroup.Content>
                      <Autocomplete
                        field="REGION"
                        value={region}
                        handleChange={value => handleChange({
                          target: { name: 'region', value },
                        })}
                        name="region"
                        id="region"
                        placeholder="Any country or city..."
                        autoComplete={noAuto()}
                      />
                      <FormGroup.Help>
                        e.g. United States or New York
                      </FormGroup.Help>
                    </FormGroup.Content>
                  </FormGroup>
                </SearchForm.Field>
                <SearchForm.Field last>
                  <FormGroup inline>
                    <FormGroup.Label htmlFor="food">
                      Food
                    </FormGroup.Label>
                    <FormGroup.Content>
                      <Autocomplete
                        field="FOOD"
                        value={food}
                        handleChange={value => handleChange({
                          target: { name: 'food', value },
                        })}
                        name="food"
                        id="food"
                        placeholder="Any food type or group..."
                        autoComplete={noAuto()}
                      />
                      <FormGroup.Help>
                        e.g. Apples or Fruit and Veg
                      </FormGroup.Help>
                    </FormGroup.Content>
                  </FormGroup>
                </SearchForm.Field>
                <SearchForm.Action>
                  <Button type="submit">Search</Button>
                  <SearchForm.AdvancedLink href="#" onClick={toggleAdvanced}>
                    Advanced Search
                  </SearchForm.AdvancedLink>
                </SearchForm.Action>
              </SearchForm>
              <AdvancedSearch
                isOpen={advancedOpen}
                onRequestClose={toggleAdvanced}
                regionValue={region}
                foodValue={food}
              />
            </Spacing>
            <TargetMeasureAct />
            <SustainableDevelopment />
            <ReducingFoodWaste />
            <Organizations />
          </Layout.Main>
        </Layout>
      </Wrap>
    </>
  );
};

export default Home;
