import styled from 'styled-components';
import { times } from 'lib/styled-helpers';

/** @component */
export const Wysiwyg = styled.div`
  max-width: 40em;

  /* High dpi */
  @media
  (-webkit-min-device-pixel-ratio: 1.5),
  (min-resolution: 144dpi){
    font-weight: 300;
  }

  @media screen and (min-width: ${props => props.theme.breakpoint.max}) {
    font-size: ${props => props.theme.font.size.lg};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${props => props.theme.colors.brandSecondary};
    margin: 0 0 ${props => props.theme.spacing.xs} 0;
  }

  p {
    margin: 0 0 ${props => props.theme.spacing.md} 0;
  }

  a {
    text-decoration: none;
  }

  ul,
  ol {
    padding: 0;
    margin-left: ${props => times(props.theme.spacing.md, 1.125)};
  }

  blockquote {
    border-left: .15em solid ${props => props.theme.colors.border};
    color: ${props => props.theme.colors.brandPrimary};
    font-size: ${props => props.theme.font.size.lg};
    margin-left: 0;
    padding-left: ${props => props.theme.spacing.md};
  }
`;
