import React from 'react';
import styled from 'styled-components';
import { SectionBox } from 'components/canvas/SectionBox/SectionBox';
import { Lead } from 'components/content/Lead/Lead';
import { Wysiwyg } from 'components/content/Wysiwyg/Wysiwyg';
import { Icon } from 'components/content/Icon/Icon';

const Img = styled.img`
  display: block;
  height: auto;
  width: 100%;
  max-width: 25em;
  margin: 0 auto;
`;

export const SustainableDevelopment = () => (
  <SectionBox>
    <SectionBox.Aside>
      <picture>
        <source media="(max-width: 47.9375em)" srcSet="/logos/sdg-long.gif" />
        <source media="(min-width: 48em)" srcSet="/logos/sdg.png" />
        <Img src="/logos/sdg.png" alt="Sustainable Development Goals Logo" />
      </picture>
    </SectionBox.Aside>
    <SectionBox.Box>
      <Lead large>
        The Food Waste Atlas supports the delivery of UN Sustainable Development Goal 12.3
      </Lead>
      <Wysiwyg>
        <blockquote>
          By 2030, halve per capita global food waste at the retail and consumer levels and reduce{' '}
          food losses along production and supply chains, including post-harvest losses.
        </blockquote>
        <a href="https://www.un.org/sustainabledevelopment/sustainable-consumption-production/" target="_blank" rel="noopener noreferrer">
          <Icon type="external" /> More about this goal
        </a>
      </Wysiwyg>
    </SectionBox.Box>
  </SectionBox>
);
