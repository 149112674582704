import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { SectionBox } from 'components/canvas/SectionBox/SectionBox';
import { Icon, StyledIcon } from 'components/content/Icon/Icon';

const IconContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing.sm};
`;

const Aside = styled(SectionBox.Aside)`
  color: ${props => props.theme.colors.brandSecondary};

  p {
    font-size: ${props => props.theme.font.size.xl};
  }
`;

const StyledLink = styled.a`
  color: ${props => props.theme.colors.brandSecondary};
  cursor: pointer;
  display: inline-block;
  transition: color ${props => `${props.theme.transition.duration} ${props.theme.transition.timing}`};
  font-weight: bold;
  margin-top: ${props => props.theme.spacing.md};

  ${StyledIcon} {
    margin: ${props => `0 ${props.theme.spacing.xs} 0 -${props.theme.spacing.xs}`};
  }

  &:hover {
    color: ${props => props.theme.colors.brandPrimary};
  }
`;

const LogoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  transform: translate(-1px, 1px); /* negate borders */
`;

const LogoGridItem = styled.div<{ large?: boolean; full?: boolean }>`
  border-left: 1px solid ${props => props.theme.colors.background};
  border-bottom: 1px solid ${props => props.theme.colors.background};
  width: ${props => props.full ? '100%' : '50%'};

  @media(min-width: ${props => props.theme.breakpoint.tablet}) {
    height: 50%;
    width: ${props => props.large ? '33.3333333%' : '25%'};
  }
`;

const LogoLink = styled.a`
  color: ${props => props.theme.colors.bizmark};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: ${props => props.theme.spacing.md};
`;

const LogoImg = styled.img`
  display: block;
  max-height: 5em;
  max-width: min(10em, 100%);
  height: 100%;
  width: auto;
`;

const LogoCaption = styled.div`
  display: block;
  text-align: center;
  width: 100%;
  font-size: ${props => props.theme.font.size.sm};
`;

export const Organizations = () => (
  <SectionBox>
    <Aside>
      <IconContainer>
        <Icon type="heart" size="jumbo" color="brandPrimary" />
      </IconContainer>
      <p>
        This project is only made possible through the <strong>dedicated support</strong>{' '}
        from these organizations.
      </p>
      <Link href="/about" passHref>
        <StyledLink>
          <Icon type="arrow-right" size="lg" /> Learn more
        </StyledLink>
      </Link>
    </Aside>
    <SectionBox.Box flush>
      <LogoGrid>
        <LogoGridItem large full>
          <LogoLink href="https://wrap.org.uk/" target="_blank" rel="noopener noreferrer">
            <LogoImg src="/logos/wrap.svg" alt="WRAP logo" width="224" height="116" />
            <LogoCaption>Project Founder and Funder</LogoCaption>
          </LogoLink>
        </LogoGridItem>
        <LogoGridItem large>
          <LogoLink href="https://wri.org/" target="_blank" rel="noopener noreferrer">
            <LogoImg src="/logos/wri.svg" alt="World Resources Institute logo" width="612" height="252" />
            <LogoCaption>Project Founder</LogoCaption>
          </LogoLink>
        </LogoGridItem>
        <LogoGridItem large>
          <LogoLink href="https://walmart.org/" target="_blank" rel="noopener noreferrer">
            <LogoImg src="/logos/walmart-foundation.svg" alt="Walmart Foundation logo" width="420" height="315" />
            <LogoCaption>Funder</LogoCaption>
          </LogoLink>
        </LogoGridItem>
        <LogoGridItem>
          <LogoLink href="https://unenvironment.org/" target="_blank" rel="noopener noreferrer">
            <LogoImg src="/logos/un-environment.svg" alt="UN Environment Programme logo" width="652" height="652" />
            <LogoCaption>Supporter</LogoCaption>
          </LogoLink>
        </LogoGridItem>
        <LogoGridItem>
          <LogoLink href="https://wur.nl" target="_blank" rel="noopener noreferrer">
            <LogoImg src="/logos/wu.jpg" alt="Wageningen University logo" width="160" height="160" />
            <LogoCaption>Supporter</LogoCaption>
          </LogoLink>
        </LogoGridItem>
        <LogoGridItem>
          <LogoLink href="https://wbcsd.org/" target="_blank" rel="noopener noreferrer">
            <LogoImg src="/logos/wbcsd.jpg" alt="WBCSD logo" width="160" height="160" />
            <LogoCaption>Supporter</LogoCaption>
          </LogoLink>
        </LogoGridItem>
        <LogoGridItem>
          <LogoLink href="https://refed.com/" target="_blank" rel="noopener noreferrer">
            <LogoImg src="/logos/refed.svg" alt="ReFED logo" width="160" height="160" />
            <LogoCaption>Supporter</LogoCaption>
          </LogoLink>
        </LogoGridItem>
      </LogoGrid>
    </SectionBox.Box>
  </SectionBox>
);
