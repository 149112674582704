import styled from 'styled-components';

export const Form = styled.form<{ compact?: boolean }>`
  align-items: center;
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-wrap: wrap;
  ${props => props.compact ? `
    padding: ${props.theme.spacing.md};

    @media screen and (min-width: ${props.theme.breakpoint.max}) {
      padding: ${props.theme.spacing.sm} ${props.theme.spacing.md};
    }
  ` : `
    padding: ${props.theme.spacing.root};

    @media screen and (min-width: ${props.theme.breakpoint.max}) {
      padding: ${props.theme.spacing.lg} ${props.theme.spacing.max};
    }
  `}
`;

const Desktop = styled(Form)`
  display: none;

  @media screen and (min-width: ${props => props.theme.breakpoint.nav}) {
    display: flex;
  }
`;

const Title = styled.div<{ compact?: boolean }>`
  width: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoint.tablet}) {
    display: flex;
  }

  @media screen and (min-width: ${props => props.theme.breakpoint.max}) {
    display: block;
    padding-right: ${props => props.theme.spacing.md};
    width: ${props => props.compact ? '10%' : '23.33%'};
  }
`;

const TitleLink = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpoint.tablet}) {
    flex-grow: 1;
    text-align: right;
  }

  @media screen and (min-width: ${props => props.theme.breakpoint.max}) {
    text-align: left;
    line-height: 1;
    margin-top: ${props => props.theme.spacing.lg};
  }
`;

const Field = styled.div<{ first?: boolean; last?: boolean; compact?: boolean }>`
  width: 100%;
  margin-top: ${props => props.theme.spacing.md};

  @media screen and (min-width: ${props => props.theme.breakpoint.tablet}) {
    padding-right: ${props => props.first && props.theme.spacing.md};
    width: 50%;
  }

  @media screen and (min-width: ${props => props.theme.breakpoint.desktop}) {
    padding-right: ${props => props.last && props.theme.spacing.md};
    width: ${props => props.compact ? '36%' : '41.67%'};;
  }

  @media screen and (min-width: ${props => props.theme.breakpoint.max}) {
    margin-top: 0;
    width: ${props => props.compact ? '35%' : '33.33333333333%'};
  }
`;

const Action = styled.div<{ compact?: boolean }>`
  margin-top: ${props => props.theme.spacing.md};
  width: 100%;

  button {
    width: 100%;
  }

  @media screen and (min-width: 30em) {
    display: flex;

    button {
      width: auto;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoint.desktop}) {
    display: block;
    margin-top: ${props => props.compact ? '1em' : '1.35em'};
    width: ${props => props.compact ? '28%' : '16.6%'};

    button {
      width: 100%;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoint.max}) {
    margin-top: ${props => props.compact ? 0 : '.35em'};
    width: ${props => props.compact ? '20%' : '10%'};
  }
`;

const AdvancedLink = styled.a`
  display: block;
  margin-top: ${props => props.theme.spacing.sm};
  text-align: center;
  width: 100%;

  @media screen and (min-width: 30em) {
    align-self: center;
    margin: 0 0 0 ${props => props.theme.spacing.sm};
    text-align: left;
    width: auto;
  }

  @media screen and (min-width: ${props => props.theme.breakpoint.desktop}) {
    height: 0;
    margin: 0;
    position: relative;
    text-align: center;
    top: ${props => props.theme.spacing.sm};
    white-space: nowrap;
    width: 100%;
  }

`;

export const SearchForm = Object.assign(Form, {
  Desktop,
  Title,
  TitleLink,
  Field,
  Action,
  AdvancedLink,
});
