import styled from 'styled-components';

export const Section = styled.section`
  margin: ${props => props.theme.spacing.lg} 0;

  @media(min-width: ${props => props.theme.breakpoint.tablet}) {
    display: flex;
  }
`;

const Aside = styled.aside`
  background: ${props => props.theme.colors.border};
  padding: ${props => props.theme.spacing.root};

  @media(min-width: ${props => props.theme.breakpoint.tablet}) {
    width: 33.3333333%;
  }

  @media screen and (min-width: ${props => props.theme.breakpoint.max}) {
    padding: ${props => props.theme.spacing.lg};
  }
`;

const Box = styled.div<{ flush?: boolean }>`
  background: ${props => props.theme.colors.white};
  padding: ${props => !props.flush && props.theme.spacing.root};
  overflow: hidden;

  @media(min-width: ${props => props.theme.breakpoint.tablet}) {
    width: 66.6666666%;
  }

  ${props => !props.flush && `
    @media screen and (min-width: ${props.theme.breakpoint.max}) {
      padding: ${props.theme.spacing.lg} ${props.theme.spacing.max};
    }
  `}
`;

export const SectionBox = Object.assign(Section, { Aside, Box });
