import styled from 'styled-components';

export const InnerWrap = styled.div`
  padding: ${props => props.theme.spacing.root};
  width: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoint.max}) {
    padding: ${props => `${props.theme.spacing.lg} ${props.theme.spacing.max}`};
  }
`;
